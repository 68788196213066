.Success-page{
    background-image: url('../Attributes/Success.svg');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    /* padding-top: 50px ; */
    padding: 5% 0%;
    /* margin-bottom: 20px; */
}
.success-inner-div{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
}
.success-inner-div img{
    width: fit-content;
    height: fit-content;
    align-items: center;
}
.success-inner-div h1{
    color: #FFF;
text-align: center;
font-size: 24px;
font-family: Nunito;
font-style: normal;
font-weight: 700;
line-height: 33.6px;
}
.success-number-containers{
    display: flex;
    /* flex-direction: row; */
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    align-items: center;
}
.success-number-container{
    padding: 50px;
    text-align: center;
    border-radius: 10px;
background: #FFF;
box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;

}
.success-number-container h1{
    color: #000;
    text-align: center;
    font-size: 24px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.success-number-container hr{
    border-radius: 10px;
    background: #FD0;
}
.success-number-container h2{
    color: #1C7E68;
    text-align: center;
    font-size: 13px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.simple-steps-get-started{
    /* background-image: url('../Attributes/GetStartedButton.svg'); */
    padding: 15px 20px;
    border-radius: 10px;
    border: none;
background: #FD0;
box-shadow: 0px 13.333333969116211px 20px 0px rgba(255, 221, 0, 0.28);
}
.simple-step-container{
    padding: 20px;
    height: 300px;
    width: 300px;
    border-radius: 10px;
border: 0.667px solid rgba(255, 255, 255, 0.21);
background: rgba(0, 0, 0, 0.10);
}
.simple-step-container h1{
    color: #FD0;
    text-align: center;
    font-size: 56px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 400;
    line-height: 112px;
}
.simple-step-container h2{
    color: #FFF;
text-align: center;
font-size: 17.333px;
font-family: Nunito;
font-style: normal;
font-weight: 700;
line-height: 34.667px;

}
.simple-step-container p{
    color: #AAA;
    text-align: center;
    font-size: 16px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}
/* CSS code */
.reviews-container {
    width: 80%;
    margin: 0 auto;
  }
  
 .reviews-container h1 {
    text-align: center;
  }
  
  .reviews {
    display: flex;
    overflow-x: scroll;
  }
  
  .review {
    width: 300px;
    margin: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
  }
  
  .review img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  
  .review p {
    font-style: italic;
  }
  
  .review span {
    font-weight: bold;
  }
  
  /* Cool effect: scale the review on hover */
  .review:hover {
    transform: scale(1.1);
  }
  
/* Created By : Mohammad Al Hefel */
:root {
    --main-color: #1C7E68;
  }
  * {
    padding: 0;
    margin: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
  }
  .container {
    width: 100%;
    height: 100%;
    padding: 0 8%;
    position: inherit;
    /* background-color: #FD0;
    overflow: scroll; */
  }
  .container h2 {
    text-align: center;
    padding-top: 6%;
    margin-bottom: 60px;
    font-size: 1.8rem;
    font-weight: 600;
    position: relative;
  }
  .container h2::after {
    content: "";
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 5px;
    background-color: var(--main-color);
    border-radius: 20px;
  }
  .row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 30px;
    /* background-color: #FD0;
    width: 100%; */
  }
  .row .service {
    padding: 25px 15px;
    /* background: #1C7E68; */
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    font-size: 15px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    transition: 0.5s;
  }
  .row .service:hover {
    color: #fff;
    background-color: var(--main-color);
    transform: scale(1.05);
  }
  .row .service i {
    color: var(--main-color);
    margin-bottom: 20px;
    font-size: 40px;
    transition: 0.5s;
  }
  .row .service:hover i {
    color: #fff;
  }
  .row .service h2 {
    font-weight: 600;
    margin-bottom: 20px;
  }
  /* .service{
    background-color: !important #1C7E68;
  } */
.info-page{
    background-image: url('../Attributes/infoBg.svg');
    width: 100%;
height: 90vh;
position: relative;
}
.phone-email-address-contianer{
    position: absolute;
    top:20% ;
    left:10% ;
    height: 400px;
    width: 500px;
    border-radius: 12.612px;
background: #FD0;
}
.map-box{
    height: 600px;
    width: 700px;
    background-color: red;
    right: 10%;
    top: 10px;
    position: absolute;
}


.footer-container{
    /* bottom: 0px; */
    /* background-color: #d3d8eb; */
    /* background-image: url('../Attributes/FooterBg.svg'); */
    background-image: url('../Attributes/Success.svg');
    background-repeat: no-repeat;
    background-size: cover;
    height: fit-content;
    /* width: 100%; */
    text-align: left;
    /* padding: 20px; */
}
.footer-upper-box{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 2rem;

}
.flexBoxes{
    display: flex;
    flex-direction: column;
    gap:1rem;
    justify-content: center;

}
.inner-divs{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}
.map-container{
    width: 100px;
    height: 200px;
    border: 1px solid yellowgreen;
}
.flexBoxes a{
    text-decoration: none;
    color: black;
    color: #DDD;
font-size: 15px;
font-family: Nunito;
font-style: normal;
font-weight: 700;
line-height: 36px;
}
.inner-divs address{
    color: #DDD;
font-size: 15px;
font-family: Nunito;
font-style: normal;
font-weight: 700;
line-height: 36px;
}
/* hr{
    margin: 10px auto;
    width: 80%;
} */
.copyright{
    /* float: right; */
    /* right: 0; */
}
.call-us-free{
    /* background-color: beige; */
    background-image: url('../Attributes/callFreeBackground.svg');
    display: flex;
    justify-content: space-around;
    align-items: center;
    overflow: visible;
    height: 265px;
    text-align: left;
    /* padding: auto 50px; */

}
.call-details{
    /* padding: 30px auto; */
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.call-us-free h1{
    color: #FFF;
font-size: 28px;
font-family: Nunito;
font-style: normal;
font-weight: 700;
/* line-height: 39.2px; */
}
.random{
    width: 100%;
    height: 200px;
}
.random-2{
    width: 100%;
    height: 200px;
}
.call-us-free h6{
    color: #FD0;
font-size: 14.667px;
font-family: Nunito;
font-style: normal;
font-weight: 400;
/* line-height: 29.333px; */
}
.call-us-free h2 a{
    color: #FFF;
font-size: 28px;
font-family: Nunito;
font-style: normal;
font-weight: 700;
line-height: 39.2px;
}


@media (max-width: 768px){
    .foot{
        margin-top: 100%;
    }
    .footer-upper-box{
        flex-direction: column;
        gap: 20px;
    }
    .crew-img{
        display: none!important;
    }
    .call-details{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        text-align: center;

    }
    .random{
        margin-top: -350px;
        width: 100%;
        height: 100px;
    }
    .random-2{
        width: 100%;
        height: 100px;
    }
}
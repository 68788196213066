.contactUs{
    /* background-color: #028196; */
    height: fit-content;
    width: auto;
    padding: 30px 10px 30px 30px;
    /* position: relative; */
    align-items: center;
    display: flex; 
    flex-direction: row;
    justify-content: space-around;
}
.contactUs img{
   height: 600px;
   width:600px;
}
.contact-container{
    /* position: absolute;  */
    right: 2%;
    background-color: #1C7E68;
    border-radius: 20px;
    width: 45%;
    padding-bottom: 20px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;

}
.contact-container h1{
    color: yellow;
    margin: 30px auto;
}
.row-1-input{
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    margin: auto ;
    padding: 0px;
    width: 80%;
    

}
.contact-container input , select{
    width: 50%;
    margin-bottom: 20px;
    padding: 15px;
    border-radius: 10px;
    border: none;
    /* justify-content: space-between; */
}

.contact-container textarea{
    width: 80%;
    margin-bottom: 20px;
    padding: 15px;
    border-radius: 10px;
    border: none;
    height: 150px;
    
}

.contact-container .contact_us_send_button{
    background: rgba(255, 221, 0, 0.932);
    font-size: large;
    border: none;
    border-radius: 10px;
    padding: 20px;
    width: 200px;
}
.contact_us_send_button:hover{
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
    background:#FD0;
}
@media (max-width: 768px){
    .contactUs{
        display: flex;
        flex-direction: column;
        /* justify-content: space-around; */
        align-items: center;
        /* gap: -10px; */
        padding: 0px;
    }
    .contactUs img{
        width: 100%;
        height: 100%;
        /* display: none; */
    }
    .contact-container button{
        width: 50%;
        background-color: #FD0;
        color: #000;
    }
    .contact-container h1{
        font-size: 18px;
    }
    .contact-container{
        /* position: absolute; */
        /* bottom: 30px; */
        /* top: 80%; */
        width: 95%;
        /* right: 2; */
        
    }
    .row-1-input{
        flex-direction: column;
        gap: 0rem;
    }
    .contact-container input , select{
        width: 100%;
       margin-bottom: 10px;
     
    }
}